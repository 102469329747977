import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import '../../Assets/css/StyleListado.css'
import React, { useState, useEffect, forwardRef  } from 'react';
import { useNavigate } from "react-router-dom";
// import { DetalleModal } from '../../componentes/detalle/DetalleModal';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import '../../Assets/css/din_style_3.css'
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
// import { InputComboBoxCaja } from "../agregar/caja/InputComboBoxCaja";
// import { InputText } from "../../componentes/editar/InputText";
// import { InputComboBoxTextId } from "../../componentes/agregar/InputComboBoxTextId";

function ListadoView(props) {
  const { url, tabla, idprimary } = props; /* cuerpoTable, nombre_controller_modal,idprimaryModal   onChange*/
  //Redux
  const user = useSelector((state) => state.user);
  const [Idempresa, setIdempresa] = useState(user.companyId);
  const [Idusuario, setIdusuario] = useState(user.userId);
  // const [Idrol, setIdrol] = useState(user.idRol);
  // Función para actualizar el usuario en el estado local cuando cambie en Redux
  useEffect(() => {
      setIdempresa(user.companyId);
      setIdusuario(user.userId);
      // setIdrol(user.idRol);
  }, [user]);
  //Caja
  const navigate = useNavigate();
  const [loading, setLoading]=useState(false);
  const [loadingPag, setLoadingPag] = useState(false);
  const [data, setData]=useState([]);
  const [page, setPage]=useState(0);//Controlar la posición actual de la página
  const [count, setCount]=useState(0);//controlar el cambio del total de páginas
  const [form, setForm]=useState({idcajaapertura:'', cajanom:'', idestado:'', page:0, buscar:true}); // form es la variable formulario de estado que tiene los datos
  const [secondForm, setSecondForm]=useState({idcajaapertura:'', cajanom:'', idestado:'', page:0, buscar:true}); // podria usarse este estado si el formulario de busqueda quede en un componente diferente
  //const [Idempresa] = useState(3);              // <<<<<< CAMBIO
  useEffect(()=>{
    if(form.buscar){
        searchData(form);
    }
    // eslint-disable-next-line
  },[form,user.companyId]);
  const searchData= async (data)=>{
      setLoading(true);
      try {
          const token = localStorage.getItem('token-dincors');
          const jsonData = {
              idempresa:user.companyId,
              idusuario: user.userId,
              idcajaapertura:data.idcajaapertura,
              cajanom:data.cajanom,
              idestado:data.idestado,
              pagina:data.page,
              idrol:user.idRol,
              idpage:localStorage.getItem('idpage') ? localStorage.getItem('idpage') : 0,
          };
          const response = await fetch(`${url}/${tabla}/${tabla}Pages`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
              },
              body:JSON.stringify(jsonData)
          });
          if(response.ok){
              const data = await response.json();
              if(data.res){
                  setData(data.datos);
                  setPage(page => page + data.pag);
                  setCount(data.pag);
                  if(localStorage.getItem('idpage')){
                    localStorage.removeItem('idpage');
                  }
              }else{
                  setCount(0);
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
      }catch (error) {
        console.log(error.message)  
          // setToastBackground('#CC6868');
          // setToastMessage(error.message);
          // setShowToast(true);
      }finally{
          setLoading(false);
      }
  }
 
  const searchForm=(form)=>{
    setForm(form);
    setPage(0);
  }

  const firstPage=()=>{
      setForm({...form, page: 0});
      setPage(0);
  }

  const nextPage=()=>{
      setLoadingPag(true);
      setForm({...form, page: page});
      setTimeout(() => {
        setLoadingPag(false);
      }, 600);
  };

  const handleSearch=()=>{
    searchForm(secondForm);
  };

  const handleChange=(e)=>{
    setSecondForm({
        ...secondForm,
        [e.target.name]:e.target.value
    });
  };

  // Exportar a Excel
  const handleExportToExcel = async () => {
    try{
      const jsonData = {
        idempresa: Idempresa,
        idusuario: Idusuario,
      }
      const token = localStorage.getItem('token-dincors');
      const response = await fetch(`${url}/${tabla}/${tabla}ExportExcel`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(jsonData),
      });
      let data;
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          const blobData = await response.blob(); // Espera a que se resuelva la promesa
          data = blobData;
        } else {
          data = await response.json(); // Espera a que se resuelva la promesa
        }
      }else{
          throw new Error(`${response.status} ${response.statusText}`);
      }
      if (data instanceof Blob) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Cierres de Caja.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setToastBackground('#198754');
        setToastMessage('Exportacion generada con exito'); // data.msg
        setShowToast(true);
      } else {
        setToastBackground('#CC6868');
        setToastMessage('Error al realizar la exportacion');
        setShowToast(true);
      }
    }catch (error) {
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    }
  };
  // ====================== TOAST ======================
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastBackground , setToastBackground ] = useState('#198754');
  // ====================== MODAL DETALLE ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [showDetalle, setShowDetalle] = useState(false);
  const CloseDetalle = () => setShowDetalle(false);
  //const [selectedIdDetalle, setSelectedIdDetalle] = useState(null);
  const [DataDetalle, setDataDetalle] = useState([]);
  const handleShowDetalle = async (itemId) => {
    //setSelectedIdDetalle(itemId);
    setShowDetalle(true);
    const jsonData = {
      Idcajaapertura: itemId,
      Idempresa: Idempresa,
      Idusuario: Idusuario,
    };
    const token = localStorage.getItem('token-dincors');
    try{
      const response = await fetch(`${url}/${tabla}/${tabla}Detail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonData),
      })
      if(response.ok){
        const data = await response.json();
        console.log(data)
        if(data.res){
            setDataDetalle(data.datos);
            setToastBackground('#198754');
            setToastMessage(data.msg);
            setShowToast(true);
        }else{
            throw new Error(data.msg);
        }
      }
    }catch(error){
      setToastBackground('#CC6868');
      setToastMessage(error.message);
      setShowToast(true);
    };
  };
  // ====================== MODAL IMPRIMIR ======================
  // Imprimir Reporte
  const handleImprimirReporte = async (id) => {
    const jsonData = {
        Idcajaapertura: id,
        Idempresa: Idempresa,
        Idusuario: Idusuario,
    };
    const token = localStorage.getItem('token-dincors');
    //console.log("datpres: ", jsonData)
    try {
        //setLoadingSearch(true);
        const response = await fetch(`${url}/${tabla}/${tabla}ImprimirReporte`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData),
        });
        if(response.ok){                
            const data = await response.json();
            console.log("Cierre: ",data) //respuesta del json de facturacion
            if(data.res){
                const response2 = await fetch(`http://localhost:8080/ImprimirCierre`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    body: JSON.stringify(data.datos),
                });
                if(response2.ok){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error('Operacion Detenida. Error al conectar con la impresion.');

                }
            }else{
                throw new Error(data.msg);
            }
        }else  {
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }catch (error){
        setToastBackground('#CC6868');
        setToastMessage(error.message);
        setShowToast(true);
    }
  };
  // ====================== MODAL CUADRAR ======================
  // Agrega un estado para controlar la visibilidad del modal
  const [ShowCuadrar, setShowCuadrar] = useState(false);
  const handleCloseCuadrar = () => setShowCuadrar(false);
  const [SelectIdCuadrar, setSelectIdCuadrar] = useState(null);
  const handleShowCuadrar = (itemId) => {
      setSelectIdCuadrar(itemId);
      setShowCuadrar(true);
  };
  const FnCajaCuadrar = async () => {
      const jsonData = {
          Idcajaapertura: SelectIdCuadrar,
          Idempresa: Idempresa,
          Idusuario: Idusuario,
      };
      const token = localStorage.getItem('token-dincors');
      try{
          const response = await fetch(`${url}/${props.tabla}/${props.tabla}Cuadrar`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(jsonData),
          })
          if(response.ok){
              const dataResponse = await response.json();
              console.log("cuadre: ",dataResponse)
              if(dataResponse.res){
                  localStorage.setItem('toastMessage1', dataResponse.msg);
                  localStorage.setItem('toastBackground1', '#198754');
                  navigate(`/PlanillasUpd/${SelectIdCuadrar}`);
                  setToastBackground('#198754');
                  setToastMessage(dataResponse.msg);
                  setShowToast(true);
              }else{
                  throw new Error(dataResponse.msg);
              }
          }
      }catch(error){
        setToastBackground('#CC6868');
        setToastMessage(error.message);
        setShowToast(true);
      }finally{
        handleCloseCuadrar();
      };
  };
  // Redireccionar con navigate
  const handleRedirect = (url) => {
    navigate(url);
  };
  
  return (
    <section className="container-fluid mt-3">
      <div className="card m-1">
        <h5 className="card-header din-card-header">LISTADO</h5>
        <div className="card-body">
          {loading ? (
                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                  <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                  &nbsp;&nbsp;<h1>Cargando datos...</h1>
                </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className='row mb-2'>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <label>ID de Caja</label>
                      <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="idcajaapertura" onChange={handleChange} value={secondForm.idcajaapertura}/>
                    </div>
                    <div className='col-12 custom-col'>
                      <label>Nombre de Caja</label>
                      <input className='form-control w-100' style={{paddingRight:'0'}} type="text" placeholder="Buscar..." name="cajanom" onChange={handleChange} value={secondForm.cajanom}/>
                    </div>
                    <div className="col-12 custom-col">
                      <label>Estado</label>
                      <select className="form-select" id="idestado" name="idestado" onChange={handleChange} value={secondForm.idestado}>
                        <option value="0">- Seleccione la Opcion -</option>
                        <option value="1">ABIERTA</option>
                        <option value="2">CERRADA</option>
                    </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row '>
                <div className='col-12'>
                  <div className='row p-1 d-flex justify-content-end'>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-success din-btn-style w-100" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;Buscar</button>
                    </div>
                    <div className='col-12 custom-col'>
                      <button className="btn btn-secondary din-btn-style w-100" onClick={handleExportToExcel}><i className="fa-solid fa-download"></i>&nbsp;&nbsp;Exportar</button>{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='row d-flex justify-content-end'>
                    <div className='col-12 custom-col-pagination'>
                      <div className='m-1'>
                        <Pagination className='justify-content-end'>
                          {page > 10 && 
                            <Pagination.Prev m={1} onClick={firstPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              PRIMERO
                            </Pagination.Prev>}
                          {count === 10 && 
                            <Pagination.Next m={1} onClick={nextPage} style={{width:90, textAlign:'center', pointerEvents: loadingPag ? 'none' : 'auto'}}>
                              {loadingPag ? (
                                <>
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </>
                              ) : (
                                'SIGUIENTE'
                              )}
                            </Pagination.Next>}
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1">
                <div className='col-12'>
                  <div className="table" style={{ marginTop: '5px'}} id="table_id">
                    <div className='tbody mx-3' >
                    <table className="table table-striped table-bordered dt-responsive din-table-1" style={{ marginTop: '5px' }} >
                          <thead>
                            <tr>
                              <th style={{width:"3%"}}></th>
                              <th style={{width:"4%"}}>CIERRE<br/>CAJA</th>
                              <th style={{width:"12%"}}>FECHA APERTURA</th>
                              <th style={{width:"12%"}}>FECHA CIERRE</th>
                              <th style={{width:"8%"}}>SALDO INICIAL</th>
                              <th style={{width:"7%"}}>DEPOSITO</th>
                              <th style={{width:"7%"}}>RETIRO</th>
                              <th style={{width:"7%"}}>VENTAS</th>
                              <th style={{width:"8%"}}>SALDO DE HOY</th>
                              <th style={{width:"8%"}}>FALTANTE / SOBRANTE</th>
                              <th style={{width:"8%"}}>ESTADO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 ? (
                              data.map((item, index) => (
                                <tr key={index}>
                                  <td className='text-center align-middle'>{/* handleShow={handleShow} handleRedirect={handleRedirect} handleShowDepositar={handleShowDepositar} handleShowRetirar={handleShowRetirar}*/}
                                    <MyDropdown tabla={props.tabla} item={item} idprimary={idprimary}  estado={item.idestado} handleRedirect={handleRedirect}
                                     handleShowDetalle={handleShowDetalle}  handleShowCuadrar={handleShowCuadrar} handleImprimirReporte={handleImprimirReporte}/>
                                  </td>
                                  <td className='text-center align-middle'><span className={`text-black texto-small badge bg-warning`}>{item.idcajaapertura}</span></td>
                                  <td className='text-center align-middle'>{item.apertura_fecha}</td>
                                  <td className='text-center align-middle'>{item.cierre_fecha}</td>
                                  <td className='text-center align-middle'>{item.saldo_apertura}</td>
                                  <td className='text-center align-middle'>{item.monto_deposito}</td>
                                  <td className='text-center align-middle'>{item.monto_retiro}</td>
                                  <td className='text-center align-middle'>{item.monto_calculado}</td>
                                  <td className='text-center align-middle'>{item.saldo_apertura}</td>
                                  <td className='text-center align-middle'>{item.diferencia}</td>
                                  <td className='text-center align-middle'>{item.idestado === 1 ? 'ABIERTA' : 'CERRADA'}</td>
                                </tr>
                                ))
                              ) : (
                              <tr>
                                <td className='text-center' colSpan={11}>Elija los filtros para encontrar los registros</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          )}
          {/* Modal Detalle*/}
          <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
              <Modal.Header className='bg-success text-white' closeButton>
                <Modal.Title style={{ textTransform: 'uppercase' }}>Cierre de {DataDetalle.cajanom} | ID CIERRE #{DataDetalle.idcajaapertura}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* CARD BODY */}
                <div className="card-body mx-2 p-3">
                  <div className='row m-1 mb-0'>
                        <div className='p-1 pb-0'>
                          Saldo Final de Caja:&nbsp;
                          <span style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.saldo_total_caja ? parseFloat(DataDetalle.saldo_total_caja).toFixed(2) : "0.00"}</span>
                        </div>
                  </div>
                  <hr style={{borderTop: "2px solid #198754", opacity:"0.6"}}/>
                    <div className='row m-2'>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                  <div className='col-12'>
                                      <div className='row'>
                                          <div className='col-12 text-center border-bottom p-2' style={{fontWeight:'600'}}>INGRESOS</div>
                                          <div className='col-7 p-1 px-4 pt-3'>Saldo inicial:&nbsp;</div>
                                          <div className='col-5 p-1 px-4 pt-3' style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.saldo_apertura ? parseFloat(DataDetalle.saldo_apertura).toFixed(2) : "0.00"}</div>
                                          <div className='col-7 p-1 px-4'>Deposito:&nbsp;</div>
                                          <div className='col-5 p-1 px-4' style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.monto_deposito ? parseFloat(DataDetalle.monto_deposito).toFixed(2) : "0.00"}</div>
                                          <div className='col-7 p-1 px-4'>Ventas:&nbsp;</div>
                                          <div className='col-5 p-1 px-4' style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.monto_pagado ? parseFloat(DataDetalle.monto_pagado).toFixed(2) : "0.00"}</div>
                                          <div className='col-7 p-1 px-4'><li>Efectivo:&nbsp;</li></div>
                                          <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.pago_efectivo ? parseFloat(DataDetalle.pago_efectivo).toFixed(2) : "0.00"}</div>
                                          <div className='col-7 p-1 px-4 '><li>Tarjeta:&nbsp;</li></div>
                                          <div className='col-5 p-1 px-4 '>{DataDetalle.moneda} {DataDetalle.pago_tarjeta ? parseFloat(DataDetalle.pago_tarjeta).toFixed(2) : "0.00"}</div>
                                          <div className='col-7 p-1 px-4 pb-3'><li>Ventas Externas:&nbsp;</li></div>
                                          <div className='col-5 p-1 px-4 pb-3'>{DataDetalle.moneda} {DataDetalle.ventas_internas ? parseFloat(DataDetalle.ventas_internas).toFixed(2) : "0.00"}</div>
                                      </div>
                                  </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 text-center border-bottom p-2' style={{fontWeight:'600'}}>EGRESOS</div>
                                        <div className='col-7 p-1 px-4 pt-3 pb-3'>Retiros:&nbsp;</div>
                                        <div className='col-5 p-1 px-4 pt-3 pb-3' style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.monto_retiro ? parseFloat(DataDetalle.monto_retiro).toFixed(2) : "0.00"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{borderTop: "2px solid #198754", opacity:"0.6"}}/>
                    <div className='row m-2'>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                <div className='col-7 p-1 px-4 pt-3'>Saldo de Hoy:&nbsp;</div>
                                <div className='col-5 p-1 px-4 pt-3'>{DataDetalle.moneda} {DataDetalle.saldo_cierre ? parseFloat(DataDetalle.saldo_cierre).toFixed(2) : "0.00"}</div>
                                <div className='col-7 p-1 px-4'>Valor Calculado:&nbsp;</div>
                                <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.venta_total ? parseFloat(DataDetalle.venta_total).toFixed(2) : "0.00"}</div>
                                <div className='col-7 p-1 px-4'>Valor Contado:&nbsp;</div>
                                <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.monto_contado ? parseFloat(DataDetalle.monto_contado).toFixed(2) : "0.00"}</div>
                                <div className='col-7 p-1 px-4'>Saldo Faltante:&nbsp;</div>
                                <div className='col-5 p-1 px-4'>
                                    {DataDetalle.diferencia && parseFloat(DataDetalle.diferencia) < 0 ? 
                                        <span className='text-danger'>{DataDetalle.moneda} {DataDetalle.diferencia}</span> 
                                      :
                                        <span>{DataDetalle.moneda} 0.00</span>
                                    }   
                                </div>
                                <div className='col-7 p-1 px-4 pb-3'>Saldo Sobrante:&nbsp;</div>
                                <div className='col-5 p-1 px-4 pb-3'>
                                    {DataDetalle.diferencia && parseFloat(DataDetalle.diferencia) > 0 ? 
                                        <span className='text-success'>{DataDetalle.diferencia }</span>
                                      : 
                                        <span>{DataDetalle.moneda} 0.00</span>
                                    } 
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                <div className='col-7 p-1 px-4 pt-3 pb-3'>Total Pendiente:&nbsp;</div>
                                <div className='col-5 p-1 px-4 pt-3 pb-3'>
                                    {DataDetalle.pendiente ? 
                                        <span>{DataDetalle.moneda} {parseFloat(DataDetalle.pendiente).toFixed(2)}</span>
                                      :
                                        <span>{DataDetalle.moneda} 0.00</span>
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                  <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                </button>
              </Modal.Footer>
          </Modal>
          {/* Modal Cuadrar*/}
          <Modal show={ShowCuadrar} onHide={handleCloseCuadrar}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Cuadrar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Aceptar para confirmar el cuadre de caja.
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseCuadrar}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnCajaCuadrar}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
              </button>
            </Modal.Footer>
          </Modal>
          {/* Modal Imprimir*/}
          {/* <Modal show={ShowImprimir} onHide={handleCloseImprimir}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Retiro de Dinero</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              XXXXX
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseImprimir}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnCajaImprimir}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
              </button>
            </Modal.Footer>
          </Modal> */}
          
          {/* Toast */}
          <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
            style={{ position: 'fixed',top: '20px',right: '20px',zIndex: 1100,background: `${toastBackground}` }}>
            <Toast.Header>
                <strong className="me-auto">Notificación</strong>
                <small></small>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </section>
  );
}
// Define la función CustomDropdownToggle
const CustomDropdownToggle = forwardRef((props, ref) => {
  const { children, onClick } = props;
  return (
    <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
      {children}
    </button>
  );
});
// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
  const { handleShowDetalle, handleShowCuadrar, handleImprimirReporte, handleRedirect} = props; // handleShow, handleRedirect, handleShowDepositar, handleShowRetirar, handleShowCierre
  const customDropdownRef = React.createRef();
  return (
    <Dropdown>
      <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
        <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
      </Dropdown.Toggle>
      <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
        <Dropdown.Item onClick={() => handleShowDetalle(props.item[props.idprimary])}>
          <i className="fas fa-calendar-week"></i> Detalle
        </Dropdown.Item>
        { props.estado === 1 ?
          <Dropdown.Item onClick={() => handleShowCuadrar(props.item[props.idprimary])}>
            <i className="fas fa-cash-register"></i> Cuadrar Caja
          </Dropdown.Item>
          :
          <Dropdown.Item onClick={() => handleRedirect(`/${props.tabla}Upd/${props.item[props.idprimary]}`)}>
            <i className="fas fa-eye"></i> Ver
          </Dropdown.Item >
        }

        <Dropdown.Item onClick={() => handleImprimirReporte(props.item[props.idprimary])}>
          <i className="fas fa-print"></i> Imprimir
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={() => handleShowCierre(props.item[props.idprimary])}>
          <i className="fas fa-calendar-check"></i> Cierre
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}


// Listado Modal
// function ListarDatosModal(url,controlador, id) {
//   return axios.get(`${url}/${controlador}/${controlador}List/${id}`, { withCredentials: true })
//   .then(function (response) {
//       if (response.status === 200) {
//           const datos = response.data.datos.map(item => ({
//               ...item,
//               fechreg: item.fechreg.replace('T', ' '),
//               fechact: item.fechact.replace('T', ' ')
//           }));
//           return datos;
//       } else {
//           throw new Error("Error en la respuesta del servidor");
//       }
//   })
//   .catch(function (error) {
//   throw error;
//   });
// }

export {ListadoView};